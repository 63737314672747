import { lazy } from 'react';

import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

const Calls = Loadable(lazy(() => import('pages/Calls')));
const SIP = Loadable(lazy(() => import('pages/SIP')));
const Software = Loadable(lazy(() => import('pages/Software')));
const Credit = Loadable(lazy(() => import('pages/Credit')));
const Home = Loadable(lazy(() => import('pages/Home')));
const Dashboard = Loadable(lazy(() => import('pages/dashboard/index')));

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '',
      element: <Home />
    },
    {
      path: 'credit',
      element: <Credit />
    },
    {
      path: 'calls',
      element: <Calls />
    },
    {
      path: 'software',
      element: <Software />
    },
    {
      path: 'sip',
      element: <SIP />
    }
  ]
};

export default MainRoutes;
