import { Outlet } from 'react-router-dom';

import { styled } from '@mui/system';

const BackgroundContainer = styled('div')({
  backgroundImage: 'url("/img/background.jpeg")',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  minHeight: '100vh', // Altezza minima per coprire l'intero viewport
});

const MinimalLayout = () => (
  <>
    <BackgroundContainer>
      <div>
        <Outlet />
      </div>
    </BackgroundContainer>
  </>
);

export default MinimalLayout;
