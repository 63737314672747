// assets
import { DashboardOutlined, AuditOutlined, ContactsOutlined, 
  RotateRightOutlined, DollarOutlined, EuroCircleOutlined, 
  CreditCardOutlined, CopyOutlined, ShoppingOutlined, DownloadOutlined, ShakeOutlined, PhoneOutlined} from '@ant-design/icons';

// icons
const icons = {
  DashboardOutlined, AuditOutlined, ContactsOutlined, RotateRightOutlined, 
  DollarOutlined, EuroCircleOutlined, CreditCardOutlined, CopyOutlined, 
  ShoppingOutlined, PhoneOutlined, DownloadOutlined, ShakeOutlined
};

const sections = {
  id: 'group-portal',
  title: 'Portale',
  type: 'group',
  children: [
    {
      id: 'calls',
      title: 'Lista Chiamate',
      type: 'item',
      url: '/calls',
      icon: icons.PhoneOutlined,
      breadcrumbs: false
    },
    {
      id: 'credit',
      title: 'Gestione Credito',
      type: 'item',
      url: '/credit',
      icon: icons.CreditCardOutlined,
      breadcrumbs: false
    },
    {
      id: 'software',
      title: 'Software',
      type: 'item',
      url: '/software',
      icon: icons.DownloadOutlined,
      breadcrumbs: false
    },
    {
      id: 'sip',
      title: 'Impostazioni SIP',
      type: 'item',
      url: '/sip',
      icon: icons.ShakeOutlined,
      breadcrumbs: false
    }
  ]
};

export default sections;
