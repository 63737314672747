import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';

const Logo = () => {
  const theme = useTheme();

  return (
    
      <Typography variant="h3">CallWebCom</Typography>
    
  );
};

export default Logo;
