import { useRoutes, Navigate } from 'react-router-dom';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';

export default function ThemeRoutes() {
  
  let routes = [
    MainRoutes,
    LoginRoutes
  ];

  return useRoutes(routes);
}
